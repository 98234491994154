import { createGlobalStyle } from 'styled-components'
import '@fontsource/press-start-2p'

const GlobalStyles = createGlobalStyle`

html{
    scroll-behavior: smooth;
    overflow-x: hidden;
}

*,*::before,*::after{
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Press Start 2P', sans-serif;
    overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
a{
    color: inherit;
    text-decoration:none;
}
`

export default GlobalStyles
