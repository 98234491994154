export const main = {
  body: '#1BFF00',
  text: '#fff', // black shade
  bodyRgba: '32,32,32',
  textRgba: '255, 255, 255',

  carouselColor: '#EEEDDE',

  fontxs: '0.75em',
  fontsm: '0.875em',
  fontmd: '1em', // 1em = 16px
  fontlg: '1.25em',
  fontxl: '2em',
  fontxxl: '3em',
  fontxxxl: '4em',

  fontButton: '0.875em',

  navHeight: '5rem',
}
