import React from 'react'
import styled from 'styled-components'
import amongusGIF from '../assets/amongussy.gif'
import ScrollingText from '../components/ScrollingText'
import Logo from '../components/Logo'

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  position: relative;
  background-color: ${(props) => props.theme.body};
  background-image: url(${amongusGIF});
`

const Container = styled.div`
  min-height: 80vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Box = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.body};
  padding: 2em 6em;
  margin: 2em;
`

const LinkBox = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.body};
  padding: 2em 6em;
  margin: 2em;
  @media (max-width: 64em) {
    flex-direction: column;
  }
`

const Link = styled.a`
  background-color: #00ffe7;
  margin: 2em;
`

const Title = styled.h1`
  font-size: 6em;
  text-align: center;
  color: #3800ff;

  @media (max-width: 64em) {
    font-size: 2em;
  }
`

const Subtitle = styled.p`
  margin: 1em;
  font-weight: bold;
  background: linear-gradient(
    to right,
    #ef5350,
    #f48fb1,
    #7e57c2,
    #2196f3,
    #26c6da,
    #43a047,
    #eeff41,
    #f9a825,
    #ff5722
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`

const Header = styled.h2`
  font-size: 4em;
  color: #e300ff;
  @media (max-width: 64em) {
    font-size: 1.5em;
  }
`

const Text = styled.p`
  margin: 1em;
`

const Home = () => {
  return (
    <Section id="home">
      <Container>
        <br></br>
        <ScrollingText></ScrollingText>
        <Logo></Logo>
        <ScrollingText></ScrollingText>
        <Box>
          <Title>WELCOME TO DICKUNGUS</Title>
          <Subtitle>ERC404 TOTALLY ON CHAIN NFT</Subtitle>
        </Box>
        <LinkBox>
          <Link href="https://twitter.com/Dickungus404" target="_blank" rel="noopener noreferrer">
            TWITTER
          </Link>
          <Link
            href="https://opensea.io/collection/dickungus"
            target="_blank"
            rel="noopener noreferrer"
          >
            OPENSEA
          </Link>
          <Link
            href="https://dexscreener.com/ethereum/0x38c5a14a6025734c7417a8a140933a6d35958da6"
            target="_blank"
            rel="noopener noreferrer"
          >
            DEXSCREENER
          </Link>
        </LinkBox>
        <Box>
          <Header>ABOUT US</Header>
          <Text>
            Dickungus, the culmination of digital creativity and cultural resonance, stands as a
            testament to the power of memes in the digital age. With each pixel meticulously
            crafted, these NFTs represent more than just images – they embody a shared understanding
            and appreciation among a diverse community of memers
          </Text>
          <Text>
            United by a common love for internet humor and irreverence, the creators behind
            Dickungus have forged a space where art and absurdity collide. Through the decentralized
            platform of the Ethereum blockchain, they have immortalized iconic phallic symbols, from
            the gleaming golden member to the enigmatic obsidian obelisk, in the form of NFTs.
          </Text>
          <Text>
            But Dickungus is more than just a collection of images; it's a journey of exploration
            and camaraderie. As you traverse the digital landscape of Dickungus, you'll encounter
            not only the familiar faces of memes past but also the vibrant personalities of the
            sussy-baka army – a legion of meme enthusiasts bound by their shared passion for the
            absurd and the unexpected.
          </Text>
          <Text>
            Joining the Dickungus community isn't just about owning a piece of digital art; it's
            about embracing the spirit of creativity and collaboration. Whether you're a seasoned
            memer or a curious newcomer, there's a place for you within the ranks of the sussy-baka
            army. So take the plunge, embark on this phallic odyssey. From the legendary
            golden-cladded penis to the mythical unspoken-of big black cock, a journey of a thousand
            miles begins with a single stroke
          </Text>
        </Box>
      </Container>
    </Section>
  )
}

export default Home
