import GlobalStyles from './styles/GlobalStyles'
import { main } from './styles/Themes'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './screens/Home'
import React from 'react'

function App() {
  return (
    <main>
      <GlobalStyles />
      <ThemeProvider theme={main}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </main>
  )
}

export default App
