import React from 'react'
import styled, { keyframes } from 'styled-components'

const scrolling = keyframes`
    0% {
        left: 100%
    }
    100% {
        left: -100%
    }
`

const Banner = styled.div`
  font-size: 3em;
  width: 100%;
  overflow: hidden;
  z-index: 100;
  color: #ff0100;

  .scrolling {
    left: 100%;
    width: 100%;
    animation: ${scrolling} 3s linear infinite;
    display: flex;
    justify-content: around;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    position: absolute;
  }

  .scrolling2 {
    animation-delay: 1.5s;
  }

  span {
    padding-left: 1em;
  }
`

const ScrollingText = () => {
  return (
    <Banner reverse={false}>
      <div className="scrolling">
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
      </div>
      <div className="scrolling scrolling2">
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
        <span>SUS</span>
      </div>
    </Banner>
  )
}

export default ScrollingText
