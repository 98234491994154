import React from 'react'
import styled, { keyframes } from 'styled-components'
import amongusLogo from '../assets/among_us_logo_2.png'

const shake = keyframes` 
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-3deg); }
  20% { transform: translate(-3px, 0px) rotate(3deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(3deg); }
  50% { transform: translate(-1px, 2px) rotate(-3deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-3deg); }
  80% { transform: translate(-1px, -1px) rotate(3deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-3deg); }
`

const Img = styled.img`
  animation: ${shake} 0.3s;
  animation-iteration-count: infinite;
`

const Logo = () => {
  return <Img src={amongusLogo}></Img>
}

export default Logo
